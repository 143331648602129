import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  createBrowserRouter as RRDcreateBrowserRouter,
  RouterProvider,
} from 'react-router'

import { defaultLocale, dynamicActivate } from './i18n'
import { routes } from './router/routes'

const createBrowserRouter = import.meta.env.PROD
  ? Sentry.wrapCreateBrowserRouterV7(RRDcreateBrowserRouter)
  : RRDcreateBrowserRouter

const router = createBrowserRouter(routes)

const App = () => {
  useEffect(() => {
    dynamicActivate(defaultLocale)
  }, [])

  return (
    <I18nProvider i18n={i18n}>
      <RouterProvider router={router} />
    </I18nProvider>
  )
}
export default App
